@import url(https://fonts.googleapis.com/css2?family=PT+Sans&family=PT+Sans+Narrow&display=swap);
body {
  margin: 0;
  font-family: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #131313;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  min-height: 100vh;
}

.opacity-0 {
  opacity: 0;
}

video {
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0.7;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  transition: opacity 0.6s ease-in-out;
}

#main-row {
  height: 100vh;
}

/* Safari Fix */
@supports (-webkit-hyphens: none) {
  #root {
    min-height: -webkit-fill-available;
  }

  #main-row {
    max-height: -webkit-fill-available;
  }

  video {
    height: -webkit-fill-available;
  }
}

